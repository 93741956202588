let $provinceSelect;
let $storesIndexContainer;

$(document).ready(function () {

    function handleStoresIndexAnchor() {
        $('.anchor[href^="#"]').click(function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 90
            }, 500);
        });
    }

    function handleProvinceSelection(evt) {
        let province = $provinceSelect.val();

        if (evt && $provinceSelect.data("linkable")) {
            let pageUrl = window.location.href;
            window.location.href = pageUrl + '/' + province.toLowerCase().split(" ").join( "-");
        } else {
            let $currentProvince = $storesIndexContainer.find('.grid.extended');
            $currentProvince.removeClass('extended');
            $currentProvince.hide();

            let $selectedProvince = $storesIndexContainer.find('.grid[data-province="' + province + '"]');
            $selectedProvince.addClass('extended');
            $selectedProvince.show();
        }
    }

    $storesIndexContainer = $('.stores-locator .stores-index');
    $provinceSelect = $('#select-province');

    $provinceSelect.select2({
        minimumResultsForSearch: 1,
        matcher: function matchCustom(params, data) {
            // If there are no search terms, return all the data
            if ($.trim(params.term) === '') {
                return data;
            }

            // Do not display the item if there is no 'text' property
            if (typeof data.text === 'undefined') {
                return null;
            }

            if (data.text.toLowerCase().startsWith(params.term.toLowerCase())) {
                return data;
            }

            return null;
        }
    }).on('select2:open', function (evt) {
        let $searchInput = $(".select2-search__field[aria-controls='select2-select-province-results']");
        $searchInput.attr('placeholder', searchProvincePlaceholder);
        $(this).blur().hide();
    }).on('select2:close', function (evt) {
        $(this).blur().show();
    });

    $provinceSelect.on('change', handleProvinceSelection);

    handleProvinceSelection();
    handleStoresIndexAnchor();

    // adjust content function
    let adjust = function () {
        let $windowWidth = $(window).width();
        if ($windowWidth >= 992) {
            let $windowHeight = $(window).height();
            let headerHeight;
            let $header = $(".header__row")
            if($header.length > 0){
                headerHeight = $header.height();
            }else{
                headerHeight = $(".megaMenu").height();
            }

            let $contentHeight = $windowHeight - headerHeight;
            $(".stores-locator__component").css("height", $contentHeight.toString() + "px");

        } else {
            $(".stores-locator__component").css("height", "auto");
        }
    };

    // call the adjust function when the page is ready
    adjust();

    // call the adjust function  when the window is resized
    $(window).resize(function () {
        adjust();
    });
});
